import React from "react";
import "./Footer.css";
import wp from "../../assets/whatsapp.png";
import fb from "../../assets/facebook.png";
import ig from "../../assets/instagram.png";
import tiktok from "../../assets/tiktok.png";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="footer-container" id="about">
      <hr />
      <div className="footer">
        <div className="social-links">
          <img src={wp} alt="" />
          <img src={fb} alt="" />
          <img src={ig} alt="" />
          <img src={tiktok} alt="" />
        </div>
        <div className="logo-f">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
